
    import { Component, Vue } from "vue-property-decorator";
    import AdminTable from "@/components/admin/AdminTable.vue";

    @Component({
        components: {
            AdminTable
        }
    })
    export default class AdminSteamBotProxy extends Vue {
        columns = [
            {
                name: "_id",
                required: true,
                label: "_id",
                field: "_id",
                align: "left",
                type: "string"
            },
            {
                name: "appId",
                required: true,
                label: "appId",
                field: "appId",
                align: "left",
                type: "number"
            },
            {
                name: "countPerDay",
                align: "center",
                label: "countPerDay",
                field: "countPerDay",
                editable: true,
                type: "number"
            },
            {
                name: "countPerWeek",
                align: "center",
                label: "countPerWeek",
                field: "countPerWeek",
                editable: true,
                type: "number"
            },
            {
                name: "countPerMonth",
                align: "center",
                label: "countPerMonth",
                field: "countPerMonth",
                editable: true,
                type: "number"
            }
        ];

    }
